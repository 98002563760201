import React from "react"
import Img from "../components/responsiveCloudinary"

import InternalHeader from "../components/internalHeader"
import GetInTouch from "../components/getInTouch"

import ux_icon from "../assets/images/ux_icon.svg"
import ui_icon from "../assets/images/ui_icon.svg"
import app_icon from "../assets/images/app_icon.svg"
import cloud_icon from "../assets/images/cloud_icon.svg"
import strategy_icon from "../assets/images/strategy_icon.svg"
import webdesign_icon from "../assets/images/webdesign_icon.svg"
import GlobalComponents from "../components/globalcomponents"
import styles from "../assets/styles/pages/services.module.scss"
import styled from "styled-components"
import { Row, media } from "../utils/style-utils"
import { Link } from "gatsby"

const ButtonLinkDefault = styled(Link)`
  color: #fff;
  font-size: 1.8rem;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme["neon-pink"]};
  padding: 22px 34px;
  display: inline-block;
  background: transparent;
  line-height: 2rem;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    padding: 24px 40px;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${(props) => props.theme["purple-alt"]};
    text-decoration: none;
    outline: none;
  }
`

const ButtonLinkHollow = styled(ButtonLinkDefault)`
  border-color: ${(props) => props.theme.purple};
  color: ${(props) => props.theme.purple};
  width: 100%;
  text-align: center;

  @media (min-width: 1024px) {
    width: initial;
  }

  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }
`

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 100%;
  max-width: 100%;

  ${media.large`
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    padding-bottom: 40px;
  `};
`

const ServicesPage = () => (
  <GlobalComponents>
    <InternalHeader
      title="What we do"
      description="People first products for brave businesses. We sepcialise in UX Design, UI Design, Web Development, Digital Strategy, App Development and Cloud Architechture"
      flag="Services"
      img="new-hero-01_2x_1_co5mdt"
      template="background"
    />

    <Services styleType="services">
      <div className="columns large-12">
        <h2>People first products for brave businesses.</h2>
        <span>We specialise in:</span>
      </div>

      <Row>
        <ServiceCard title="User Experience Design" icon={ux_icon}>
          Empathy, research, and proven UX patterns come together to form
          polished, intuitive online experiences. Take your customers on a
          journey, not a wild goose chase.
        </ServiceCard>

        <ServiceCard title="User Interface Design" icon={ui_icon}>
          Great design is transparent. Guided by usability and branding we make
          sure every pixel has its place.
        </ServiceCard>

        <ServiceCard title="Web Development" icon={webdesign_icon}>
          When it comes to full-stack development, we’re the full package. Our
          websites join immersive, standards-based front-end development with
          nimble back-end architecture.
        </ServiceCard>

        <ServiceCard title="Digital Strategy" icon={strategy_icon}>
          Measure twice, cut once. We make decisions and build experiences
          backed by analytics, positioning, and as much data as we can get our
          hands on.
        </ServiceCard>

        <ServiceCard title="App Development" icon={app_icon}>
          Value is app-solutely essential for an engaging customer experience.
          (See what we did there?) We build compelling native and web-based apps
          for all devices and screen sizes.
        </ServiceCard>

        <ServiceCard title="Cloud Architecture" icon={cloud_icon}>
          When it comes to fast, secure servers that are close to your
          customers, the sky’s the limit! We’ve implemented cloud-based
          solutions through AWS, DigitalOcean, Netlify, and more.
        </ServiceCard>
      </Row>

      <div className="columns large-12">
        <div className={styles.centerButtons}>
          <ButtonLinkHollow to="/work">View our work</ButtonLinkHollow>
          <ButtonLinkHollow to="/about">Get to know us</ButtonLinkHollow>
        </div>
      </div>
    </Services>

    <Services styleType="customers">
      <div className="columns large-12">
        <h2 className={styles.diagonalTitle}>
          Our services are scalable for teams and projects of all sizes.
        </h2>
        <span>We collaborate with:</span>
      </div>

      <Customer title="Startups &amp; SMEs">
        Starting from scratch? That’s exciting! We’ll fill in the gaps, lending
        an expert, helping hand to get your big idea up and running.
      </Customer>

      <Customer title="Product Companies">
        You’ve been in the game a while, and you’re ready to make your next
        move. Customer data in tow, we’ll work with you to enhance and elevate
        your offering.
      </Customer>

      <Customer title="Enterprise Businesses">
        Fortune 500 companies and other corporate big dogs trust us to work as
        part of their teams, navigating murky corporate waters to create
        compliant, compelling technical solutions.
      </Customer>

      <Customer title="Agencies &amp; Studios">
        Yes, you read that right! For us, great work takes priority over ego and
        competition. We’ve worked with partner agencies to produce award-winning
        digital transformations.
      </Customer>

      <div className="columns large-6">
        <Img
          className={styles.logoStripLeft}
          user="avenue"
          publicId="logos1_x4ciw2"
          alt=""
        />
      </div>
      <div className="columns large-6">
        <Img
          className={styles.logoStripRight}
          user="avenue"
          publicId="logos2_huqdq3"
          alt=""
        />
      </div>
    </Services>
    <GetInTouch
      left="In coffee we trust."
      center="The best ideas start with great coffee. You pick the spot, we’ll pick up the tab."
      right="Let's have a chat"
      action="/contact"
    />
  </GlobalComponents>
)

const Services = ({ children, styleType }) => (
  <section className={styles[styleType]}>
    <div className="row">{children}</div>
  </section>
)

const ServiceCard = ({ title, icon, children }) => (
  <Col>
    <div className={styles.service}>
      <img src={icon} alt={title} />
      <div>
        <h3>{title}</h3>
        <p>{children}</p>
      </div>
    </div>
  </Col>
)

const Customer = ({ title, children }) => (
  <div className="columns large-3">
    <div className={styles.customer}>
      <h3>{title}</h3>
      <p>{children}</p>
    </div>
  </div>
)

export default ServicesPage
